const Footer_RightLine_SVG = ( { ...props } ) => {
	return (
			<svg
					xmlns="http://www.w3.org/2000/svg"
					width="574.68"
					height="382.618"
					viewBox="0 0 574.68 382.618"
					
					{...props}
			>
				<path
						id="Path_144"
						data-name="Path 144"
						d="M-19385.572,5851.339s163.217-368.715,565.531-368.57"
						transform="translate(19394.717 -5472.769)"
						fill="none"
						strokeWidth="20"
				/>
			</svg>
	)
}

export default Footer_RightLine_SVG
