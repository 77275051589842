const Arrow_Right_Footer_SVG = ({
	                                ...props
                                }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='15.601'
			height='11.683'
			viewBox='0 0 15.601 11.683'

			fill={'none'}

			{...props}
		>
			<g
				id='Group_160'
				data-name='Group 160'
				transform='translate(29.92 -7.159) rotate(90)'
			>
				<line
					id='Line_9'
					data-name='Line 9'
					y1='14.363'
					transform='translate(13.001 15.557)'
					strokeMiterlimit='10'
					strokeWidth='1.75'
				/>
				<path
					id='Path_141'
					data-name='Path 141'
					d='M7.778,20.78,13,15.557l5.223,5.223'
					transform='translate(0 0)'
					strokeMiterlimit='10'
					strokeWidth='1.75'
				/>
			</g>
		</svg>
	)
}

export default Arrow_Right_Footer_SVG
