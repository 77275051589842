import React, {useRef, useState} from 'react'
import cn                        from 'classnames'
import styles                    from '../styles/components/NewsletterForm.module.sass'

const NewsletterForm = ({
	                        id,
	                        className,
	                        isFooter = false,
	                        subdescription = 'En vous inscrivant à la newsletter, vous acceptez de recevoir des nouvelles de notre marque.',
                        }) => {

	//region References
	const nameRef         = useRef()
	const [name, setName] = useState('')
	const mailRef         = useRef()
	const [mail, setMail] = useState('')
	//endregion

	//region States
	const [sendingState, setSendingState] = useState('none')
	//endregion

	const sendNewsletterEmail = () => {
		setSendingState('sending')

		//region Get data
		let formData = []

		//region Get fields values
		// Name
		formData.push({
			              name:  'Nom',
			              value: nameRef.current?.value ?? nameRef.current?.element?.value ?? '',
		              })

		// Mail
		formData.push({
			              name:  'Mail',
			              value: mailRef.current?.value ?? mailRef.current?.element?.value ?? '',
		              })
		//endregion

		fetch(
			'/api/newsletter',
			{
				method:  'POST',
				headers: {
					'Accept':       'application/json',
					'Content-Type': 'application/json',
				},
				body:    JSON.stringify(formData),
			},
		)
			.then(res => {
				// If success
				if (res.status === 200) {
					setSendingState('sent')
				} else {
				}
			})
	}

	return (
		<div
			className={cn(
				styles.newsletter_form,
				className,
				{[styles.isFooter]: isFooter},
			)}
		>
			<h4 className={styles.quote}>
				Inscrivez-vous pour connaître les dernières nouveautés
			</h4>

			<form
				className={styles.form}
			>
				<div className={styles.container}>
					<div className={styles.inputs}>
						{/* Name */}
						<label className={styles.name}>
							<input
								type='text'
								id={`${id}_name`}
								placeholder={'Jacques Dupont'}
								ref={nameRef}
								value={name}
								onChange={e => setName(e.target.value)}
							/>
							<span className={styles.label}>Nom Prénom</span>
						</label>

						{/* Mail */}
						<label className={styles.mail}>
							<input
								type='text'
								id={`${id}_mail`}
								placeholder={'mail@mail.com'}
								ref={mailRef}
								value={mail}
								onChange={e => setMail(e.target.value)}
							/>
							<span className={styles.label}>Mail</span>
						</label>
					</div>
					<span className={styles.legend}>{subdescription}</span>
				</div>

				<button
					className={cn(
						styles.send_button,
						{
							[styles.sent]: sendingState === 'sent',
						},
					)}
					type={'button'}

					disabled={sendingState !== 'none' || (
					          name === '' || mail === ''
					)}

					onClick={sendNewsletterEmail}
				>
					{sendingState === 'none' && <>
						S&apos;inscrire à la newsletter
					</>}
					{sendingState === 'sending' && <>
						Envoi en cours...
					</>}
					{sendingState === 'sent' && <>
						Inscription réussie
					</>}
				</button>
			</form>
		</div>
	)
}

export default NewsletterForm
