import SocialNetwork_Line_SVG               from './svg/lines/SocialNetwork_Line_SVG'
import styles                               from '../styles/components/SocialNetwork_WithLine.module.sass'
import React, {useEffect, useRef, useState} from 'react'

import gsap          from 'gsap/dist/gsap'
import DrawSVGPlugin from 'gsap/dist/DrawSVGPlugin'
import Link          from 'next/link'
import cn            from 'classnames'

gsap.registerPlugin(DrawSVGPlugin)

const SocialNetwork_WithLine = ({
	                                href,
	                                className,
	                                children,
	                                socialName = '',
                                }) => {

	const lineRef      = useRef()
	const lineTimeline = useRef()

	const [hovered, setHovered] = useState(false)

	//region Handlers
	useEffect(
		() => {
			lineTimeline.current = gsap
				.timeline({defaults: {duration: .5}})
				.fromTo(
					lineRef.current,
					{
						drawSVG: 0,
					},
					{
						drawSVG: '100%',
					},
				)
				.pause()

			return () => {
				console.log('KILL')
				lineTimeline.current?.kill()
			}
		},
		[],
	)

	useEffect(
		() => {
			if (hovered) {
				lineTimeline.current?.play()
			} else {
				lineTimeline.current?.reverse()
			}
		},
		[hovered],
	)
	//endregion

	return (
		<Link
			scroll={false}
			href={href}
			className={cn(
				className,
				styles.link,
			)}

			aria-label={`Visiter notre profil${' '
			                                   + socialName}`}

			target={'_blank'}

			//region Handlers
			onMouseEnter={() => {
				setHovered(true)
			}}
			onMouseLeave={() => {
				setHovered(false)
			}}
			//endregion
		>
			<SocialNetwork_Line_SVG
				className={styles.line}
				ref={lineRef}
			/>
			{children}
		</Link>
	)
}

export default SocialNetwork_WithLine
