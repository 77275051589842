import {forwardRef} from "react";

const SocialNetwork_Line_SVG = forwardRef(({...props}, ref) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="38.619"
			height="39.404"
			viewBox="0 0 38.619 39.404"

			{...props}
		>
			<path
				id="Path_156"
				data-name="Path 156"
				d="M10.75-10.037S-7.414-5.77-7.414,8.948,3.323,27.394,10.75,27.394,29.205,24.921,29.205,8.948s-16.361-12.4-16.361-12.4"
				transform="translate(8.414 11.011)"
				fill="none"
				strokeWidth="2"

				ref={ref}
			/>
		</svg>
	)
})

SocialNetwork_Line_SVG.displayName = 'SocialNetwork_Line_SVG'

export default SocialNetwork_Line_SVG
