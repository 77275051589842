const Footer_LeftLine_SVG = ( { ...props } ) => {
	return (
			<svg
					xmlns="http://www.w3.org/2000/svg"
					width="485.842"
					height="221.317"
					viewBox="0 0 485.842 221.317"
					
					{ ...props }
			>
				<path
						id="Path_145"
						data-name="Path 145"
						d="M-20773.6,5287.844s87.273,264.18,474.645,197.306"
						transform="translate(20783.096 -5284.707)"
						fill="none"
						strokeWidth="20"
				/>
			</svg>
	)
}

export default Footer_LeftLine_SVG
